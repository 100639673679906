<template>
  <section class="h-100">

    <div class="m-auto text-center mb-7">
      <img
        src="@/assets/images/menu_2.svg"
        class="w-full"
      >

      <img
        src="@/assets/images/setup_profile/step_1.svg"
        class="mx-auto mt-5"
      >
      <span class="text-neutral">Step 1 of 10</span>
    </div>

    <div class="flex h-screen text-center" style="height: 80vh;">

      <div class="m-auto pb-10 w-4/5">

        <div class="mb-12 relative">
          <span>This will take approximately 20 minutes to complete</span>
        </div>

        <div class="mt-9">
          <h3 class="text text-center text-black mb-4">
            The Basics
          </h3>

          <a href="#" class="text-primary font-bold">Why do you need all this data?</a>
        </div>

        <form
          class="mt-10 mb-20"
          @submit.prevent="register"
        >

          <div
            v-if="errorMessage"
            class="flex items-center text-dark text-sm mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-6">
            <input
              v-model="fullName"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Full Name"
              required
            >

            <input
              v-model="preferredName"
              type="text"
              class="form-control w-1/4"
              placeholder="Nickname"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="email"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Email Address"
              required
            >

            <input
              v-model="phoneNumber"
              type="text"
              class="form-control w-1/4"
              placeholder="Phone Number"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="username"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Username"
              required
            >

            <input
              v-model="industry"
              type="text"
              class="form-control w-1/4"
              placeholder="Industry"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="title"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Title"
              required
            >

            <input
              v-model="neighborhood"
              type="text"
              class="form-control w-1/4"
              placeholder="Neighborhood"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="localCity"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Local City"
              required
            >

            <input
              v-model="ethnicity"
              type="text"
              class="form-control w-1/4"
              placeholder="Ethnicity"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="country"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Country"
              required
            >

            <input
              v-model="politicalViews"
              type="text"
              class="form-control w-1/4"
              placeholder="Political Views"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="relationship"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Relationship"
              required
            >

            <input
              v-model="spouse"
              type="text"
              class="form-control w-1/4"
              placeholder="Spouse"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="birthdate"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Birthdate"
              required
            >

            <input
              v-model="hometown"
              type="text"
              class="form-control w-1/4"
              placeholder="Hometown"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="religion"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Religion"
              required
            >

            <input
              v-model="annualIncome"
              type="text"
              class="form-control w-1/4"
              placeholder="Annual Income"
              required
            >
          </div>

          <div class="mb-6">
            <input
              v-model="languages"
              type="text"
              class="form-control mr-5 w-1/4"
              placeholder="Languages"
              required
            >

            <input
              v-model="professionalSkills"
              type="text"
              class="form-control w-1/4"
              placeholder="Professional Skills"
              required
            >
          </div>
        </form>

        <div class="">
          <router-link
            :to="{name: 'v2.profile.about'}"
            class="btn btn-primary-outline btn-large align-self-center me-auto mr-5"
          >
            Finish Later
          </router-link>

          <router-link
            :to="{name: 'v2.profile.about'}"
            class="btn btn-primary btn-large align-self-center me-auto"
          >
            Onwards
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BasicProfile',
  data() {
    return {
      fullName: '',
      firstName: '',
      middleName: '',
      lastName: '',
      preferredName: '',
      email: '',
      phoneNumber: '',
      username: '',
      hometown: '',
      industry: '',
      title: '',
      neighborhood: '',
      localCity: '',
      annualIncome: '',
      religion: '',
      languages: '',
      professionalSkills: '',
      ethnicity: '',
      country: '',
      politicalViews: '',
      relationship: '',
      spouse: '',
      birthdate: '',
      errorMessage: null
    };
  },
  watch: {
    firstName: function (value) {
      console.log(value)
    }
  },
  methods: {
    submit() {
      if (!this.firstName || this.firstName === "") {
        this.errorMessage = 'Please fill in the first name field and try again.'
        return false;
      }

      localStorage.setItem('firstname', this.firstName);
      localStorage.setItem('lastname', this.middleName);
      localStorage.setItem('middlename', this.lastName);
      localStorage.setItem('preferredname', this.preferredName);

      this.$router.push('/registration/v2/signup/email');
    }
  },
  mounted() {
    let firstName = localStorage.getItem('firstname');
    let lastName = localStorage.getItem('lastname');
    let middleName = localStorage.getItem('middlename');
    let preferredName = localStorage.getItem('preferredname');

    console.log(firstName);
    console.log(middleName);
    console.log(lastName);

    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }
    if (lastName && lastName !== '') {
      this.lastName = lastName;
    }
    if (middleName && middleName !== '') {
      this.middleName = middleName;
    }
    if (preferredName && preferredName !== '') {
      this.preferredName = preferredName;
    }

    this.fullName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
